var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home",attrs:{"id":"top"}},[_c('img',{staticClass:"bannerBg home-bg",class:[
      _vm.changeOpacity == true ? 'animated rotateIn' : 'animated rotateOut'
    ],staticStyle:{"width":"100%"},attrs:{"src":require("../assets/homeBanner.jpeg"),"alt":""}}),_vm._m(0),_c('img',{attrs:{"id":"About","src":require("../assets/about.jpg"),"alt":""}}),_vm._m(1),_vm._m(2),_c('div',{staticClass:"home-carousel"},[_c('el-carousel',{attrs:{"interval":5000,"arrow":"always","height":_vm.carouselH}},_vm._l((_vm.brandList),function(item,index){return _c('el-carousel-item',{key:index},[_c('div',{staticClass:"brand-box"},[_c('img',{attrs:{"src":item.src,"alt":""}}),_c('div',{staticClass:"layer-box"})])])}),1)],1),(_vm.typeClass == 'pc')?_c('div',{staticClass:"honor-box"},[_c('div',{staticClass:"content-box"},[_c('div',{ref:"translist",staticClass:"left"},_vm._l((_vm.yearsList),function(item,index){return _c('div',{key:index,staticClass:"left-item"},[_vm._v(" "+_vm._s(item.fullName)+" ")])}),0),_c('div',{staticClass:"center"},[_c('swiper',{staticClass:"swiper",attrs:{"options":_vm.swiperOption}},_vm._l((_vm.yearsList),function(item,index){return _c('swiper-slide',{key:index,staticClass:"center-item"},[_c('div',{staticClass:"top"},[_vm._v("20")]),_c('div',{staticClass:"bottom"},[_vm._v(_vm._s(item.name))])])}),1)],1),_c('div',{staticClass:"right"},_vm._l((_vm.yearsList[_vm.swiperIndex].honorList),function(item,index){return _c('div',{key:index,staticClass:"right-item"},[_vm._v(" "+_vm._s(item)+" ")])}),0)])]):_vm._e(),_c('div',{staticClass:"culture"},[_c('div',{staticClass:"vertical-line"}),_vm._m(3),_c('div',{staticClass:"culture-img-box"},_vm._l((_vm.cultureList),function(item,index){return _c('div',{key:index,staticClass:"culture-img-item"},[_c('img',{attrs:{"src":item.isHover ? item.hoverSrc : item.src,"alt":""},on:{"mouseover":function($event){item.isHover = true},"mouseout":function($event){item.isHover = false}}})])}),0)]),_c('div',{attrs:{"id":"Contact"}})])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page1st"},[_c('h2',{staticClass:"homeH2"},[_vm._v("品牌电商长期发展合作伙伴")]),_c('h4',{staticClass:"homeH4"},[_vm._v("专注全球时尚和生活方式领域，提供一站式电商解决方案")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"CoreBusiness"}},[_c('img',{attrs:{"src":require("../assets/core.jpeg"),"alt":""}}),_c('a',{staticClass:"link-div",attrs:{"href":"https://admin.cloud.efashionchina.com","target":"_blank"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"CooperativeBrand"}},[_c('img',{attrs:{"src":require("../assets/hzpp.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"culture-title"},[_c('img',{attrs:{"src":require("../assets/cluture-title.png"),"alt":""}})])
}]

export { render, staticRenderFns }