<template>
  <div class="overseas-business">
    <img class="fixed-bg" src="../assets/servicebg.jpeg" alt="">
    <img src="../assets/service.jpg" alt="" class="block-img">
    <img src="../assets/advantage.jpeg" alt="" class="block-img">
    <img src="../assets/case.jpeg" style="background-color: #fff;" alt="">
  </div>
</template>

<script>
export default {
  name: "overseasBusiness"
};
</script>

<style scoped>
.overseas-business {
  font-size: 0;
}
.fixed-bg {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: -1;
}
.mobile .overseas-business {
  padding-top: 70px;
}
.block-img {
  display: block;
  margin: 0 auto;
}
</style>

