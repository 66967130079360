<template>
  <!-- <div class="home" id="top" :class="[typeClass == 'pc' ? 'pc' : 'mobile']"> -->
  <div class="home" id="top">
    <img
      style="width:100%;"
      class="bannerBg home-bg"
      :class="[
        changeOpacity == true ? 'animated rotateIn' : 'animated rotateOut'
      ]"
      src="../assets/homeBanner.jpeg"
      alt=""
    />
    <div class="page1st">
      <h2 class="homeH2">品牌电商长期发展合作伙伴</h2>
      <h4 class="homeH4">专注全球时尚和生活方式领域，提供一站式电商解决方案</h4>
    </div>
    <img id="About" src="../assets/about.jpg" alt="" />
    <div id="CoreBusiness">
      <img src="../assets/core.jpeg" alt="" />
      <a
        class="link-div"
        href="https://admin.cloud.efashionchina.com"
        target="_blank"
      ></a>
    </div>
    <div id="CooperativeBrand">
      <!-- <img src="../assets/cooperation.png" alt="" /> -->
      <!-- <img src="../assets/companylogos.png" alt="" /> -->
      <img src="../assets/hzpp.png" alt="" />
    </div>
    <div class="home-carousel">
      <el-carousel :interval="5000" arrow="always" :height="carouselH">
        <el-carousel-item v-for="(item, index) in brandList" :key="index">
          <div class="brand-box">
            <img :src="item.src" alt="" />
            <div class="layer-box"></div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="honor-box" v-if="typeClass == 'pc'">
      <div class="content-box">
        <div class="left" ref="translist">
          <div
            class="left-item"
            v-for="(item, index) in yearsList"
            :key="index"
          >
            {{ item.fullName }}
          </div>
        </div>
        <div class="center">
          <swiper class="swiper" :options="swiperOption">
            <swiper-slide
              class="center-item"
              v-for="(item, index) in yearsList"
              :key="index"
            >
              <div class="top">20</div>
              <div class="bottom">{{ item.name }}</div>
            </swiper-slide>
          </swiper>
        </div>
        <div class="right">
          <div
            class="right-item"
            v-for="(item, index) in yearsList[swiperIndex].honorList"
            :key="index"
          >
            {{ item }}
          </div>
        </div>
      </div>
    </div>
    <div class="culture">
      <div class="vertical-line"></div>
      <div class="culture-title">
        <img src="../assets/cluture-title.png" alt="" />
      </div>
      <div class="culture-img-box">
        <div
          class="culture-img-item"
          v-for="(item, index) in cultureList"
          :key="index"
        >
          <img
            :src="item.isHover ? item.hoverSrc : item.src"
            @mouseover="item.isHover = true"
            @mouseout="item.isHover = false"
            alt=""
          />
        </div>
      </div>
    </div>
    <div id="Contact"></div>
  </div>
</template>

<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
export default {
  name: "home",
  components: {
    swiper,
    swiperSlide
  },
  data() {
    const that = this;
    return {
      brandList: [
        {
          src: require("../assets/cos.jpg"),
          show: false,
          layerSrc: require("../assets/cos-layer.png")
        },
        {
          src: require("../assets/md.jpg"),
          show: false,
          layerSrc: require("../assets/md-layer.png")
        },
        //{
        //  src: require("../assets/ever.jpg"),
        //  show: false,
        //  layerSrc: require("../assets/ever-layer.png")
        //},
        {
          src: require("../assets/lulu.jpg"),
          show: false,
          layerSrc: require("../assets/lulu-layer.png")
        }
      ],
      isPhone: true,
      typeClass: localStorage.getItem("uAgent"),
      height: "700px",
      currentDate: new Date(),
      show: true,
      honorImgs: [{}],
      timer: null,
      changeOpacity: false,
      carouselH: "700px",
      cultureList: [
        {
          src: require("../assets/culture/culture1.jpg"),
          isHover: false,
          hoverSrc: require("../assets/culture/culture1Hover.jpg")
        },
        {
          src: require("../assets/culture/culture2.jpg"),
          isHover: false,
          hoverSrc: require("../assets/culture/culture2Hover.jpg")
        },
        {
          src: require("../assets/culture/culture3.jpg"),
          isHover: false,
          hoverSrc: require("../assets/culture/culture3Hover.jpg")
        },
        {
          src: require("../assets/culture/culture4.jpg"),
          isHover: false,
          hoverSrc: require("../assets/culture/culture4Hover.jpg")
        }
      ],
      yearsList: [
        {
          name: "23",
          fullName: "2023",
          honorList: ["淘天集团淘宝天猫服务商","2023年度综合能力评估五星服务商"]
        },
        {
          name: "21",
          fullName: "2021",
          honorList: ["品牌数据银行认证服务商"]
        },
        {
          name: "20",
          fullName: "2020",
          honorList: ["2020年高新技术企业", "2020年ebay新秀企业卖家"]
        },
        {
          name: "19",
          fullName: "2019",
          honorList: [
            "2019年信息安全管理认证ISO",
            "2019年上半年天猫五星级服务商",
            "天猫国际2019年度美洲大区“招商好伙伴”"
          ]
        },
        {
          name: "18",
          fullName: "2018",
          honorList: [
            "2018下半年天猫五星级服务商",
            "2018年度上海嘉定工业区优秀经营者",
            "2018年度最具潜力奖",
            "2018年度小巨人企业"
          ]
        },
        {
          name: "17",
          fullName: "2017",
          honorList: ["2017下半年天猫五星级服务商"]
        },
        {
          name: "16",
          fullName: "2016",
          honorList: ["2016年度企业突出贡献奖"]
        }
      ],
      swiperIndex: 0,
      swiperOption: {
        direction: "vertical",
        slidesPerView: 1,
        spaceBetween: 30,
        mousewheel: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true
        },
        on: {
          slideChangeTransitionStart: function() {
            const x = this.activeIndex * 33;
            that.$refs.translist.style.transform = "translateY(-" + x + "px)";
            that.swiperIndex = this.activeIndex;
          }
        }
      }
    };
  },
  mounted() {
    localStorage.setItem(
      "offsetTop",
      this.$el.querySelector("#Contact").offsetTop
    );
    let screenW =
      window.innerWidth ||
      document.body.clientWidth ||
      document.documentElement.clientWidth;
    screenW = (screenW * 700) / 1920;
    screenW = screenW > 700 ? 700 : screenW;
    screenW = screenW.toString();
    this.carouselH = screenW + "px";
    let uaType;
    uaType = localStorage.getItem("uAgent");
    this.isPhone = uaType === "phone" ? 1 : 0;
    window.addEventListener("scroll", this.getScroll);
    if (this.$route.params.selector !== undefined) {
      // setTimeout(() => {
      //   this.goAnchor(this.$route.params.selector);
      // }, 600)
      this.goAnchor(this.$route.params.selector);
    }
    // const that = this;
    // this.timer = setInterval(() => {
    //   if (that.honorIndex === this.honor.length - 3) {
    //     that.honorIndex = 0;
    //   } else {
    //     that.honorIndex += 1;
    //   }
    // }, 5000);
  },
  methods: {
    showList(index) {
      this.isShow[index].show = true;
    },
    hideList(index) {
      this.isShow[index].show = false;
    },
    goAnchor(selector) {
      this.utils.goAnchor(selector)
      // const anchor = this.$el.querySelector(selector); // 参数为要跳转到的元素id
      // document.body.scrollTop = anchor.offsetTop; // chrome
      // const total =
      //   selector == "#Contact"
      //     ? localStorage.getItem("offsetTop")
      //     : anchor.offsetTop;
      // let distance = window.pageYOffset;
      // let step = total / 50;
      // let upStep = (distance - total) / 50;
      // (function smoothDown() {
      //   if (distance < total) {
      //     distance += step;
      //     document.body.scrollTop = distance;
      //     document.documentElement.scrollTop = distance;
      //     setTimeout(smoothDown, 10);
      //   } else {
      //     document.body.scrollTop = total;
      //     document.documentElement.scrollTop = total;
      //   }
      // })();
      // (function smoothUp() {
      //   if (distance > total) {
      //     distance -= upStep;
      //     document.body.scrollTop = distance;
      //     document.documentElement.scrollTop = distance;
      //     setTimeout(smoothUp, 10);
      //   } else {
      //     document.body.scrollTop = total;
      //     document.documentElement.scrollTop = total;
      //   }
      // })();
    },
    // 监听滚动
    getScroll() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (scrollTop > 1080) {
        this.changeOpacity = true;
      } else if (scrollTop <= 1080) {
        this.changeOpacity = false;
      }
    }
  },
  // beforeDestroy() {
  //   clearInterval(this.timer);
  // },
  destroyed() {
    window.removeEventListener("scroll", this.getScroll);
  }
};
</script>

<style scoped>
.swiper {
  height: 240px;
  width: 100%;
}
.swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: bold;
  color: #c01920;
}
.home {
  font-size: 0;
}
.mobile .home {
  overflow-x: hidden;
}
.nav-banner {
  font-size: 0;
  position: relative;
}
.bannerBg {
  width: 100% !important;
}
.nav-banner > .bannerBg {
  z-index: -1;
  position: fixed;
  left: 0;
  top: 0;
}
.home-bg {
  z-index: -1;
  position: fixed;
  left: 0;
  top: 0;
}
.page1st {
  height: 56.25vw;
}
.homeH2 {
  font-size: 70px;
  color: #fff;
  padding-top: 25vw;
  margin-bottom: 40px;
  font-family: "PingFang";
}
.mobile .homeH2 {
  font-size: 26px;
}
.mobile .homeH4 {
  font-size: 12px;
  letter-spacing: 0;
}
.mobile #About {
  margin-bottom: 20px;
}
.mobile #CooperativeBrand {
  padding: 0 40px 20px 40px;
  margin-bottom: 20px;
}
.mobile .culture {
  padding-top: 20px;
}
.mobile .culture-title img {
  width: 30%;
}
.mobile .vertical-line {
  height: 20px;
}
.homeH4 {
  letter-spacing: 5px;
  font-size: 24px;
  font-weight: 400;
  color: #fff;
  background-color: #000;
  display: inline-block;
  font-family: "PingFang";
  padding: 0 5px;
}
#About {
  display: block;
  margin: 0 auto;
  margin-bottom: 100px;
}
#CooperativeBrand {
  width: 100%;
  background-color: #fff;
  padding-bottom: 100px;
  max-width: 1920px;
  margin: 0 auto;
}
.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}

.rotateIn {
  animation-name: rotate;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}
@keyframes rotate {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.8;
  }
}
.rotateOut {
  animation-name: rotateBack;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
}
@keyframes rotateBack {
  from {
    opacity: 0.8;
  }
  to {
    opacity: 1;
  }
}
.brand-box {
  position: relative;
}
.layer-box {
  position: absolute;
  z-index: 50;
  right: 0;
  top: 0;
  width: 50%;
  height: 100%;
  cursor: pointer;
}
.layer-box img {
  position: relative;
  right: 2px;
  top: 5px;
}
@keyframes showCarousel {
  0% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.layer-show {
  animation-name: showLayer;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}
@keyframes showLayer {
  from {
    transform-origin: center;
    transform: translate(110%, 0);
  }
  to {
    transform-origin: center;
    transform: translate(0, 0);
  }
}
.layer-hidden {
  animation-name: hiddenLayer;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
}
@keyframes hiddenLayer {
  from {
    transform-origin: center;
    transform: translate(0, 0);
  }
  to {
    transform-origin: center;
    transform: translate(110%, 0);
  }
}
.culture {
  padding-top: 50px;
  background-color: #fff;
  max-width: 1920px !important;
  margin: 0 auto;
}
.vertical-line {
  width: 50%;
  height: 100px;
  background-color: transparent;
  border-right: 1px solid #000;
}
.culture-title {
  padding: 30px 0;
}
.culture-img-box {
  font-size: 0;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  max-width: 1920px !important;
  margin: 0 auto;
}
.culture-img-box .culture-img-item {
  width: 50%;
  cursor: pointer;
}
.culture-img-item:nth-child(1) {
  border-bottom: 1px solid #fff;
}
.culture-img-item:nth-child(2) {
  border-bottom: 1px solid #fff;
}
.honor-box {
  position: relative;
  background: url(../assets/honor.jpeg) center center no-repeat;
  height: 47.976vw;
}
.honor-box .content-box {
  display: flex;
  justify-content: center;
  left: 0;
  top: 0;
  height: 100%;
  padding-top: 21vw;
}
.center {
  width: 250px;
  overflow-y: scroll;
  font-size: 70px;
  scrollbar-width: none;
}
.center-item {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  line-height: 1;
  height: 240px;
  margin-bottom: 150px;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 200px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
.swiper-slide {
  height: 1000px;
}
.swiper-slide-active {
  cursor: pointer;
  font-weight: 400;
}
.left {
  flex: 1;
  padding-top: 3.298vw;
  font-size: 16px;
  text-align: right;
}
.left-item {
  font-size: 24px;
  padding-right: 200px;
  line-height: 32px;
}
.right {
  flex: 1;
  font-size: 16px;
  text-align: left;
  padding-top: 55px;
  font-size: 24px;
}
.right-item {
  padding-left: 100px;
  line-height: 2;
}
.home-carousel {
  z-index: 9999999999;
}
.el-carousel__item.is-active.is-animating {
  background-color: transparent;
}
#CoreBusiness {
  position: relative;
}
.link-div {
  display: inline-block;
  cursor: pointer;
  position: absolute;
  left: 56.87%;
  top: 70.5%;
  width: 7.4vw;
  height: 4.2%;
}
</style>
