<template>
  <div class="overseas-business">
    <div class="header-container">
      <img
        alt="Vue logo"
        class="recruit-banner"
        src="../assets/recruitBanner.jpeg"
      />
      <div class="title-container">
        <h2 class="recruitH2">我们长期招聘以下岗位</h2>
        <h4 class="recruitH4">期待你加入奕尚大家庭</h4>
      </div>
    </div>
    <div class="job-container-box">
      <div class="vertical-line"></div>
      <div class="job-container-title">热门岗位</div>
      <div class="job-container">
        <div class="job-item-box line-one">
          <div
            class="job-item"
            v-for="(item, index) in lineOneList"
            :key="index"
          >
            <img
              @click="toJobDetails(item.job)"
              :src="item.isHover ? item.hoverSrc : item.src"
              @mouseover="item.isHover = true"
              @mouseout="item.isHover = false"
              alt=""
            />
          </div>
        </div>
        <div class="job-item-box line-two">
          <div
            class="job-item"
            v-for="(item, index) in lineTwoList"
            :key="index"
          >
            <img
              @click="toJobDetails(item.job)"
              :src="item.isHover ? item.hoverSrc : item.src"
              @mouseover="item.isHover = true"
              @mouseout="item.isHover = false"
              alt=""
            />
          </div>
        </div>
      </div>
      <transition name="slide-fade">
        <div class="detail-content" v-if="showDetail">
          <div class="content">
            <svg
              t="1581502377704"
              @click="hideDetails"
              class="icon close-icon"
              viewBox="0 0 1024 1024"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              p-id="1115"
              width="200"
              height="200"
            >
              <path
                d="M509.866667 32C245.333333 32 32 247.466667 32 512s213.333333 480 477.866667 480S987.733333 776.533333 987.733333 512 774.4 32 509.866667 32z m0 896C281.6 928 96 742.4 96 512S281.6 96 509.866667 96 923.733333 281.6 923.733333 512s-185.6 416-413.866666 416z"
                fill="#212121"
                p-id="1116"
              ></path>
              <path
                d="M693.333333 330.666667c-12.8-12.8-32-12.8-44.8 0L512 467.2l-136.533333-136.533333c-12.8-12.8-32-12.8-44.8 0-12.8 12.8-12.8 32 0 44.8l136.533333 136.533333-136.533333 136.533333c-12.8 12.8-12.8 32 0 44.8 6.4 6.4 14.933333 8.533333 23.466666 8.533334s17.066667-2.133333 23.466667-8.533334l136.533333-136.533333 136.533334 136.533333c6.4 6.4 14.933333 8.533333 23.466666 8.533334s17.066667-2.133333 23.466667-8.533334c12.8-12.8 12.8-32 0-44.8L556.8 512l136.533333-136.533333c12.8-12.8 12.8-32 0-44.8z"
                fill="#212121"
                p-id="1117"
              ></path>
            </svg>
            <div class="job-title">
              <div
                class="title-span"
                :class="[isPractice == true ? 'practice' : '']"
              >
                {{ jobName }}
              </div>
            </div>
            <div class="job-contnet-box">
              <div class="duty-box">
                <div class="duty-title">岗位职责：</div>
                <div class="duty-content">
                  <ul class="num-ul">
                    <li v-for="(item, index) in duty" :key="index">
                      {{ item }}
                    </li>
                  </ul>
                </div>
              </div>
              <div class="requirement-box">
                <div class="requirement-title">任职要求：</div>
                <div class="requirement-content">
                  <ul class="num-ul">
                    <li v-for="(item, index) in requirement" :key="index">
                      {{ item }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      lineOneList: [
        {
          src: require("../assets/positions/bk/1-1.jpg"),
          isHover: false,
          job: "StoreOperation",
          hoverSrc: require("../assets/positions/red/1-1.jpg")
        },
        {
          src: require("../assets/positions/bk/1-2.jpg"),
          isHover: false,
          job: "ContentOperation",
          hoverSrc: require("../assets/positions/red/1-2.jpg")
        },
        {
          src: require("../assets/positions/bk/1-3.jpg"),
          isHover: false,
          job: "DataAnalysis",
          hoverSrc: require("../assets/positions/red/1-3.jpg")
        },
        {
          src: require("../assets/positions/bk/1-4.jpg"),
          isHover: false,
          job: "CustomerService",
          hoverSrc: require("../assets/positions/red/1-4.jpg")
        }
      ],
      lineTwoList: [
        {
          src: require("../assets/positions/bk/2-1.jpg"),
          isHover: false,
          job: "Designer",
          hoverSrc: require("../assets/positions/red/2-1.jpg")
        },
        {
          src: require("../assets/positions/bk/2-2.jpg"),
          isHover: false,
          job: "StoreOperationPractice",
          hoverSrc: require("../assets/positions/red/2-2.jpg")
        },
        {
          src: require("../assets/positions/bk/2-3.jpg"),
          isHover: false,
          job: "CustomerServicePractice",
          hoverSrc: require("../assets/positions/red/2-3.jpg")
        }
      ],
      showDetail: false,
      job: "",
      jobName: "店铺运营",
      isPractice: false,
      duty: [],
      requirement: [],
      jobContent: {
        StoreOperation: {
          duty: [
            "负责天猫店铺各项销售指标、运营指标的预测与达成，并实时反馈、监督与改进；",
            "根据企业的要求和品牌需求，制定店铺商品活动及运营计划，对店铺的功能、布局、美观等方面进行改进完善；",
            "不断优化业务流程，提升工作效率。开发和培养员工能力，对员工进行绩效管理；",
            "定期对所负责店铺运营参数如：流量数据、营销数据、交易数据、产品管理、客户管理，市场活动等进行详细的系统分析，并提出改进方案；",
            "根据商品分类和陈列装修原则，监督管控店铺商品的陈列装修安排，并根据季节、节假日情况进行调整；",
            "定期或不定期与品牌和天猫沟通，建立和维护良好客户关系；",
            "跟踪分析竞争品牌的运营信息，积极提出应对方案。"
          ],
          requirement: [
            "大专学历以上，2年以上天猫运营管理经验；",
            "熟悉天猫的操作规范和流程，精通线上运营、推广、优化、维护等技能知识；",
            "具备丰富的市场活动策划和执行能力，以及一定的文字功底；",
            "拥有良好的应变能力，分析、决策和管理能力，自学能力强，能够迅速掌握新的知识；",
            "有强烈的责任感，上进心和事业心，勇于承担责任，能够承受较大的工作压力；",
            "具有良好的行业人脉关系；",
            "较强的语言表达能力，沟通与组织协调能力，善于与团队进行交流合作。"
          ]
        },
        ContentOperation: {
          duty: [
            "负责天猫/淘宝店铺内容搭建，包括但不局限于策划并执行店铺微淘、有好货、哇哦视频等内容运营方案，为店铺引流，为转化负责；",
            "负责天猫店铺粉丝增长、会员运营，策划行之有效的拉新活动，并落地执行，完成既定KPI；",
            "与客户、天猫小二保持良好的客情关系，负责与品牌方重大会议的统筹及准备工作；",
            "协调项目各职能部门，如设计、推广、运营、策划等，积极推动确保项目的执行和拓展，保障项目高效顺畅的达成；",
            "定期组织项目内容相关数据分析，及不定时各类活动复盘活动；",
            "参与项目月度运营计划，监控市场及竞品数据，积极探索、紧跟行业趋势，发掘新的营销手段，提升项目竞争优势。"
          ],
          requirement: [
            "有1年以上工作经验，电商经验，市场营销经验优先；",
            "本科及以上学历，有留学经历、英文专业优先；",
            "英语熟练，包括口语和书面，擅长文字撰写；",
            "学习能力强，抗压能力强，对电子商务行业感兴趣，喜欢挑战、能快速适应新的环境；",
            "性格开朗，沟通能力强，高EQ，逻辑清晰 。"
          ]
        },
        DataAnalysis: {
          duty: [
            "负责品牌每日销售数据分析；",
            "负责店铺及品牌每日、周、月、季度、年度销售数据、KPI数据等数据报表的汇总、整理、制作及分析工作；",
            "处理品牌临时的数据分析需求，及时反馈分析结果并提供相关行动建议；",
            "负责所有数据报表的制作、日常更新、整理及备份。"
          ],
          requirement: [
            "有电商行业经验者优先；",
            "精通PPT制作、EXCEL（包括但不仅限于函数、图表、数据透视表及VBA）、WORD等办公软件；",
            "能独立完成数据分析报告者优先；",
            "处事严谨，积极主动、具备较强的逻辑思维能力和沟通能力，抗压能力。"
          ]
        },
        CustomerService: {
          duty: [
            "通过在线交流工具回复顾客咨询，主动引导顾客促成销售；",
            "熟悉店铺商品，处理顾客对于商品、下单等相关问题；",
            "处理售后问题，如跟进异常订单、退换货申请、物流沟通、顾客维护等；",
            "积极处理投诉，提升客户满意度。"
          ],
          requirement: [
            "有淘宝（天猫）客服销售经验，熟悉淘宝（天猫）各类功能； ",
            "做事条理清晰，有良好的沟通能力及顾客服务意识；",
            "思路清晰，性格温和，有责任心及较好的耐心；",
            "有较强的执行能力及团队协作力；",
            "工作稳定性强，能配合部门排班要求；",
            "有良好的文字处理能力，汉字录入速度快，能同时和多人通过网络聊天工具沟通。"
          ]
        },
        Designer: {
          duty: [
            "负责公司各网站的平面图片设计工作，以符合客户活动及审美为前提；",
            "与市场部、运营部、客服部相关部门相协调，不断调整公司页面视觉，以达到最完美的图片设计来促进销售；",
            "具有独立操刀设计的能力，也有带领设计师团队工作完成项目的能力。"
          ],
          requirement: [
            "具有2-3年以上平面设计经验，具网页设计或电子商务背景的尤佳；",
            "能熟练使用ADOBE软件，如PHOTOSHOP、ILLUSTRATOR，会使用DREAMWAVER及FLASH，可以读懂基本HTML代码，对CSS、JAVA等代码有所了解。如有天猫、京东等第三方电子商务平台设计经验的更佳；",
            "具有良好的审美，对时尚及流行趋势敏感；",
            "具有责任心、耐心、纪律性，有团队合作精神，能够在压力下工作；",
            "具有良好的领导能力和沟通能力，能够合理安排和分配工作。"
          ]
        },
        StoreOperationPractice: {
          duty: [
            "学习网站的日常维护、更新，商品信息录入和修改等；",
            "参与线上活动，包括协助活动及专题的策划、落地执行；",
            "利用EXCEL等工具进行报表制作、数据处理等；",
            "协调并跟进部门之间的工作；",
            "配合其他部门项目的运营支持。"
          ],
          requirement: [
            "大专以上应届毕业生；",
            "了解和热爱电子商务工作，英语能力良好；",
            "工作认真仔细，思路清晰；",
            "具有良好的团队合作精神，能承担一定的工作压力。"
          ]
        },
        CustomerServicePractice: {
          duty: [
            "通过在线聊天的形式对客户咨询的相关问题进行解答；",
            "熟悉店铺商品，处理顾客对于商品、下单等相关问题；",
            "积极处理投诉，提升客户满意度；",
            "部门内其它相关工作。"
          ],
          requirement: [
            "大专以上应届毕业生；",
            "了解和热爱电子商务工作；",
            "工作认真仔细，思路清晰；",
            "具有良好的团队合作精神，能承担一定的工作压力。"
          ]
        }
      }
    };
  },
  mounted() {
    this.showDetail = false;
  },
  methods: {
    toJobDetails(path) {
      this.job = path;
      this.showDetail = true;
      this.duty = this.jobContent[path].duty;
      this.requirement = this.jobContent[path].requirement;
      if (
        path == "StoreOperationPractice" ||
        path == "CustomerServicePractice"
      ) {
        this.isPractice = true;
        this.jobName =
          path == "StoreOperationPractice"
            ? "店铺运营（实习）"
            : "客服专员（实习）";
      } else {
        this.isPractice = false;
        this.jobName =
          path == "StoreOperation"
            ? "店铺运营"
            : path == "ContentOperation"
            ? "内容运营"
            : path == "DataAnalysis"
            ? "数据分析"
            : path == "CustomerService"
            ? "客服专员"
            : "平面设计";
      }
    },
    hideDetails() {
      this.showDetail = false;
    }
  }
};
</script>

<style scoped>
.about-us-container {
  padding-bottom: 100px;
  font-size: 0;
}
.recruit-banner {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: -1;
}
.recruitH2 {
  font-size: 48px;
  color: #fff;
  padding-top: 300px;
  margin-bottom: 20px;
  font-family: "PingFang";
}
.recruitH4 {
  font-size: 48px;
  font-weight: 400;
  color: #fff;
  margin-bottom: 200px;
  font-family: "PingFang";
}
.recruit-title-info {
  width: 100%;
  text-align: center;
}
.recruit-title {
  font-family: PingFang-SC-Bold;
  font-size: 28px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #010101;
  margin-bottom: 30px;
}
.recruit-sec-title {
  font-family: PingFang;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #7e7e7e;
  margin-bottom: 120px;
}
.job-container-box {
  position: relative;
  width: 1500px;
  margin: 0 auto;
  margin-bottom: 50px;
  padding-bottom: 200px;
  background-color: #fff;
}
.job-container {
  width: 1200px;
  margin: 0 auto;
}
.job-item-box {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}
.job-item-box.line-two {
  margin: 0 auto;
}
.job-item {
  cursor: pointer;
  border: 1px solid transparent;
  border-collapse: collapse;
  flex: 1;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-around;
}
.job-item .image {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background-color: #d7af77;
  position: relative;
}
.job-item .image img {
  vertical-align: middle;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
.job-item .item-name {
  font-family: PingFang-SC-Heavy;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #000000;
  padding: 30px 0;
}
.job-item .button {
  cursor: pointer;
  height: 55px;
  line-height: 55px;
  background-color: #d7af77;
  border-radius: 28px;
  font-family: PingFang-SC-Heavy;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  color: #ffffff;
  position: relative;
}
.right-icon {
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 13px solid #fff;
  border-right: 0 solid transparent;
  display: inline-block;
  position: relative;
  left: 10px;
  top: 3px;
}

.detail-content {
  padding-top: 50px;
  width: 1200px;
  background: #fff;
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -600px;
  border-radius: 20px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}
.content {
  width: 1000px;
  margin: 0 auto;
}
.close-icon {
  width: 40px;
  height: 40px;
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
}
.banner {
  margin-bottom: 79px;
}
.job-title {
  margin-bottom: 79px;
}
.title-span {
  line-height: 3;
  display: inline-block;
  font-family: PingFang-SC-Heavy;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #c01920;
  border-bottom: 1px solid #c01920;
  width: 90%;
}
.num-ul {
  list-style: decimal;
}
.num-ul li {
  text-align: left;
  font-family: CenturyGothic;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: 1px;
  color: #000000;
}
.duty-box,
.requirement-box {
  width: 1320px;
  margin: 0 auto;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  margin-bottom: 79px;
}
.duty-title,
.requirement-title {
  width: 200px;
  font-family: PingFang-SC-Heavy;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #000000;
  flex-shrink: 0;
  padding-top: 3px;
}

.slide-fade-enter-active {
  transition: all 0.5s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active for below version 2.1.8 */ {
  transform: translateY(-50px);
  opacity: 0;
}
.vertical-line {
  width: 50%;
  height: 100px;
  background-color: transparent;
  border-right: 1px solid #000;
}
.job-container-title {
  width: 100%;
  text-align: center;
  font-size: 36px;
  padding: 50px 0;
}
.mobile .recruitH2 {
  font-size: 26px;
  margin: 0;
  padding: 0;
}
.mobile .recruitH4 {
  font-size: 12px;
  letter-spacing: 0;
  margin: 0;
  padding: 0;
}
.mobile .vertical-line {
  height: 20px;
}
.mobile .job-container-box {
  margin-bottom: 20px;
  padding-bottom: 0;
}
.mobile .job-container-title {
  font-size: 26px;
  padding: 20px 0;
}
.mobile .recruit-banner {
  position: relative;
}
.mobile .header-container {
  position: relative;
}
.mobile .title-container {
  position: absolute;
  left: 0;
  width: 100%;
  top: 50%;
  transform: translateY(-30%);
}
.mobile .detail-content {
  padding-top: 0;
  width: 100%;
  margin-left: 0;
  left: 0;
}
.mobile .close-icon {
  right: 15px;
  top: 15px;
  width: 30px;
  height: 30px;
}
.mobile .job-title {
  margin-bottom: 30px;
}
.mobile .duty-title,
.mobile .requirement-title {
  width: 110px;
}
.mobile .num-ul li {
  text-align: left;
  font-family: CenturyGothic;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: 0;
}
</style>
