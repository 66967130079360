<template>
  <div class="tpl-box">
    <app-header></app-header>
    <router-view></router-view>
    <app-footer></app-footer>
  </div>
</template>
<script>
import Header from '../components/Header'
import Footer from '../components/Footer'
export default {
  components: {
    'app-header': Header,
    'app-footer': Footer,
  },
  data () {
    return {
      // isShowLogin: false,
      // isLogin: true,
      // uAgent: localStorage.getItem('uAgent'),
      // title: ''
    }
  }
}
</script>
