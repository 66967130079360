<template>
  <div class="home">
    <div
      class="header-container header-bg"
      :class="[changeOpacity == true ? 'shallow' : 'deep']"
    >
      <div class="nav-bg">
        <div class="nav-container">
          <img class="left-logo" src="../assets/logo.svg" @click="aaa('Home', 0)" alt="" />
          <ul v-if="typeClass == 'pc'" class="header-nav clearfix">
            <li
              v-for="(item, index) in CnNav"
              :key="index"
              :class="[isActive == index ? 'active' : '']"
              @click="aaa(item.path, index)"
            >
              {{ item.name }}
            </li>
          </ul>
          <div v-else class="mobile-list">
            <svg v-if="showNav" @click="showNav = !showNav" t="1585810086021" class="icon nav-switch" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="7132" width="200" height="200"><path d="M853.333333 725.034667v64H170.666667v-64h682.666666z m-149.333333-309.333334l149.333333 106.666667-149.333333 106.666667v-213.333334z m-85.333333 74.666667v64H170.666667v-64h448zM853.333333 256v64H170.666667v-64h682.666666z" p-id="7133" fill="#ffffff"></path></svg>
            <!-- <svg v-if="showNav" t="1585805903432" class="icon nav-switch" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="6967" width="200" height="200"><path d="M853.333333 714.666667v64H170.666667v-64h682.666666zM320 405.333333v213.333334l-149.333333-106.666667 149.333333-106.666667z m533.333333 85.333334v64H405.333333v-64h448z m0-234.666667v64H170.666667v-64h682.666666z" p-id="6968"></path></svg> -->
            <svg v-else @click="showNav = !showNav" t="1585805903432" class="icon nav-switch" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="6967" width="200" height="200"><path d="M853.333333 714.666667v64H170.666667v-64h682.666666zM320 405.333333v213.333334l-149.333333-106.666667 149.333333-106.666667z m533.333333 85.333334v64H405.333333v-64h448z m0-234.666667v64H170.666667v-64h682.666666z" p-id="6968" fill="#ffffff"></path></svg>
            </div>
        </div>
      </div>
        <div v-if="showNav == true && typeClass == 'phone'" class="mobile-nav-box" :class="[showNav == true ? 'animated show-nav' : 'animated hidden-nav']">
          <ul>
            <li
              v-for="(item, index) in CnNav"
              :key="index"
              :class="[isActive == index ? 'active' : '']"
              @click="aaa(item.path, index)"
            >
              {{ item.name }}
            </li>
          </ul>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      changeOpacity: false,
      times: 50,
      currentDate: new Date(),
      CnNav: [
        { name: "首页", path: "Home" },
        { name: "关于奕尚", path: "#About" },
        { name: "核心业务", path: "#CoreBusiness" },
        { name: "合作品牌", path: "#CooperativeBrand" },
        { name: "海外业务", path: "OverseasBusiness" },
        { name: "人才招聘", path: "Recruit" },
        { name: "联系我们", path: "#Contact" }
        // { name: "Cn", path: "Language" }
      ],
      showNav: false,
      isActive: 0,
      scroll: 0,
      typeClass: localStorage.getItem("uAgent"),
    };
  },
  mounted() {
    //  || localStorage.getItem("index");
    // this.isActive = this.$store.state.navIndex
    this.isActive = localStorage.getItem("index");
    window.addEventListener("scroll", this.menu);
    // if (localStorage.getItem("uAgent") !== 'pc') {
    //   this.CnNav = [
    //     { name: "首页", path: "Home" },
    //     { name: "海外业务", path: "OverseasBusiness" },
    //     { name: "人才招聘", path: "Recruit" },
    //   ]
    // }
  },
  methods: {
    aaa(path, index) {
      this.$store.commit('changeIndex', index)
      this.isActive = index;
      localStorage.setItem("index", index);
      if (
        path === "Home" ||
        path === "OverseasBusiness" ||
        path === "Recruit"
      ) {
        window.scrollTo(0, 0);
        this.$router.push({ name: path });
        this.isActive = index;
      } else {
        if (this.$route.name === "Home") {
          this.goAnchor(path);
        } else {
          this.$router.push({ name: "Home", params: { selector: path } });
        }
      }
    },
    logoHref() {
      this.isActive = 0;
    },
    goAnchor(selector) {
            const anchor = this.$parent.$el.querySelector(selector);
      const total = anchor.offsetTop;
      this.utils.goAnchor(total)
      // const anchor = this.$parent.$el.querySelector(selector);
      // const total = anchor.offsetTop;
      // let distance = window.pageYOffset;
      // let step = total / 50;
      // let upStep = (distance - total) / 50;
      // (function smoothDown() {
      //   if (distance < total) {
      //     distance += step;
      //     document.body.scrollTop = distance;
      //     document.documentElement.scrollTop = distance;
      //     setTimeout(smoothDown, 10);
      //   } else {
      //     document.body.scrollTop = total;
      //     document.documentElement.scrollTop = total;
      //   }
      // })();
      // (function smoothUp() {
      //   if (distance > total) {
      //     distance -= upStep;
      //     document.body.scrollTop = distance;
      //     document.documentElement.scrollTop = distance;
      //     setTimeout(smoothUp, 10);
      //   } else {
      //     document.body.scrollTop = total;
      //     document.documentElement.scrollTop = total;
      //   }
      // })();
    },
    menu() {
      this.scroll =
        document.documentElement.scrollTop || document.body.scrollTop;
      this.changeOpacity = this.scroll > 0 ? true : false;
    }
  }
};
</script>
<style scoped>
.router-link-active {
  border-bottom: 2px solid #fff;
}

.header-container {
  position: fixed;
  top: 0;
  left: 0;
  height: 70px;
  width: 100%;
  font-size: 0;
  z-index: 99;
}
.pc .header-container {
  min-width: 1260px;
}
.header-bg {
  background: rgba(0, 0, 0, 1);
}
.header-container > img {
  width: 100%;
  z-index: -1;
}
.nav-bg {
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1001;
}
.home {
  z-index: 99;
}
.home .nav-container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  height: 70px;
  margin: 0 auto;
  padding: 15px 0;
  width: 1000px;
}
.home .nav-container img {
  height: 40px;
  line-height: 40px;
}
.header-nav {
  list-style: none;
  height: 50px;
  line-height: 48px;
}
.header-nav li {
  cursor: pointer;
  float: left;
  font-family: PingFang;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: rgb(214, 214, 214);
  margin-right: 20px;
}
.header-nav li:hover {
  color: #f5f5f7;
}
.header-nav li a {
  display: inline-block;
  cursor: pointer;
}
.header-nav li.active {
  border-bottom: 2px solid #fff;
}
.mobile li.active {
  border-bottom: 2px solid #000;
}
.clearfix {
  overflow: hidden;
  _zoom: 1;
}
.shallow {
  animation-name: shallowAnimate;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}
@keyframes shallowAnimate {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.8;
  }
}
.deep {
  animation-name: deepAnimate;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}
@keyframes deepAnimate {
  from {
    opacity: 0.8;
  }
  to {
    opacity: 1;
  }
}
.left-logo {
  cursor: pointer;
}
.mobile .header-nav li {
  font-size: 10px;
}
.mobile .nav-container {
  padding-left: 10px;
}
.mobile .left-logo {
  width: 30%;
}
.nav-switch {
  width: 45px;
  height: 40px;
  color: #fff;
}
.mobile-nav-box {
  position: absolute;
  left: 0;
  top: 70px;
  width: 100%;
  /* background-color: rgba(128, 128, 128, 0.6) */
  background-color: rgba(256, 256, 256, 0.8);
  z-index: 5;
}
.mobile-nav-box ul {
  display: flex;
  padding: 0 7px;
  justify-content: space-between;
  align-items: center;
}
.mobile-nav-box ul li {
  list-style-type: none;
  height: 50px;
  /* padding: 0 3px; */
  /* width: 50px; */
  font-size: 12px;
  line-height: 50px;
  color: #000;
}

.show-nav {
  animation-name: showNav;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}
@keyframes showNav {
  from {
    opacity: 0;
    z-index: -1
  }
  to {
    opacity: 1;
    z-index: 5
  }
}
.hidden-nav {
  animation-name: hiddenNav;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}
@keyframes hiddenNav {
  from {
    transform-origin: center;
    opacity: 1;
  }
  to {
    transform-origin: center;
    opacity: 0;
  }
}
</style>
