import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import OverseasBusiness from "../views/OverseasBusiness.vue";
// import TalentRecruitment from "../views/TalentRecruitment.vue";

import Recruit from "../views/Recruit.vue";
// import Join from "@/views/Join.vue";
// import Main from "@/views/main.vue";
import Index from "@/views/Index.vue";
// import JobDetails from "../views/recruit/JobDetails.vue";

Vue.use(VueRouter);

// const joinRoutes = {
//   path: "/",
//   component: Join,
//   redirect: "/home",
//   children: [
//     {
//       path: "home",
//       name: "Home",
//       component: Home
//     },
//     {
//       path: "overseasBusiness",
//       name: "OverseasBusiness",
//       component: OverseasBusiness
//     },
//     // {
//     //   path: "talentRecruitment",
//     //   name: "TalentRecruitment",
//     //   redirect: "home",
//     //   component: TalentRecruitment
//     // },
//     {
//       path: "recruit",
//       name: "Recruit",
//       component: Recruit
//     }
//   ]
// };

// const routes = [
//   {
//     path: "/",
//     component: Main,
//     redirect: "/",
//     children: [
//       joinRoutes,
//       {
//         path: "/jobDetails/:name",
//         name: "JobDetails",
//         component: JobDetails
//       }
//     ]
//   },

//   { path: "*", redirect: "/", hidden: true }
// ];
const routes = [
  {
    path: "/",
    component: Index,
    children: [
      {
        path: "",
        name: "Home",
        component: Home
      },
      {
        path: "overseasBusiness",
        name: "OverseasBusiness",
        component: OverseasBusiness
      },
      {
        path: "recruit",
        name: "Recruit",
        component: Recruit
      }
      // {
      //   path: "/jobDetails/:name",
      //   name: "JobDetails",
      //   component: JobDetails
      // }
    ]
  },

  { path: "*", redirect: "/", hidden: true }
];
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};

export default router;
