<template>
  <div id="app" :class="[typeClass == 'pc' ? 'pc' : 'mobile']">
    <!-- <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div> -->
    <router-view />
  </div>
</template>

<script>
export default {
  data() {
    return {
      typeClass: localStorage.getItem("uAgent"),
    }
  },
  beforecreate() {
    // localStorage.removeItem
    localStorage.getItem("uAgent");
  }
};
</script>
<style lang="less">
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html,
body {
  width: 100%;
}
#app {
  max-width: 100%;
  width: 100%;
  // min-width: 1260px;
  margin: 0 auto;
}
#app.pc {
  min-width: 1260px;
}
::-webkit-scrollbar {
  width: 0 !important;
}
::-webkit-scrollbar {
  width: 0 !important;
  height: 0;
}
#nav {
  padding: 30px;
  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
#app * {
  max-width: 100%;
}
a {
  color: #fff;
  text-decoration: none;
}
</style>
