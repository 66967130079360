export default {
  goAnchor: function (total) {
    // const anchor = this.$parent.$el.querySelector(selector);
    // // const total = anchor.offsetTop;
    // const total = selector == "#Contact" ? localStorage.getItem("offsetTop") : anchor.offsetTop;
    let distance = window.pageYOffset;
    let step = total / 50;
    let upStep = (distance - total) / 50;
    (function smoothDown() {
      if (distance < total) {
        distance += step;
        document.body.scrollTop = distance;
        document.documentElement.scrollTop = distance;
        setTimeout(smoothDown, 10);
      } else {
        document.body.scrollTop = total;
        document.documentElement.scrollTop = total;
      }
    })();
    (function smoothUp() {
      if (distance > total) {
        distance -= upStep;
        document.body.scrollTop = distance;
        document.documentElement.scrollTop = distance;
        setTimeout(smoothUp, 10);
      } else {
        document.body.scrollTop = total;
        document.documentElement.scrollTop = total;
      }
    })();
  },
}