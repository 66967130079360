import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import '@/PingFang/PingFang.css'
import utils from './util/util.js'
import vueAwesomeSwiper from 'vue-awesome-swiper'
Vue.use(vueAwesomeSwiper);
Vue.use(ElementUI)

Vue.config.productionTip = false;
Vue.prototype.utils = utils
/* 判断用户设备 */
var uAgent
var isIpad = false
if (navigator.userAgent.indexOf("Android") > -1 || navigator.userAgent.indexOf("Linux") > -1) {
  uAgent = "phone";
} else if (navigator.userAgent.indexOf("iPhone") > -1) {
  uAgent = "phone";
} else if (navigator.userAgent.indexOf("iPad") > -1) {
  uAgent = document.documentElement.clientWidth > document.documentElement.clientHeight ? "pc" : "phone";
  isIpad = true;
} else if (navigator.userAgent.indexOf("Windows Phone") > -1) {
  uAgent = "phone";
} else {
  uAgent = "pc";
}
localStorage.setItem("uAgent", uAgent);
localStorage.setItem("isIpad", isIpad);
// console.log('main')
// if (localStorage.getItem('index') == '4' || localStorage.getItem('index') == '5') {
//   // console.log('45')
// } else {
//   // localStorage.setItem('index', 0)
// }

// document.querySelector('meta[name="keywords"]').setAttribute('content', 'YiShang,E-Fashion,奕尚,服饰运营,电商运营,代运营,店铺运营,天猫五星级服务商');
// document.querySelector('meta[name="description"]').setAttribute('content', '奕尚成立于2008年，专注全球时尚和生活方式领域，为品牌提供一站式电子商务解决方案。核心服务包括品牌咨询、店铺运营、网店托管、大淘电商、电商代运营、数字营销、IT系统解决方案、客户服务等众多内容，是品牌电商长期发展合作伙伴，国内领先的电子商务服务商之一。');

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
